.select {
    box-sizing: border-box;
    width: 100%;
    padding: 0px 10px;
    height: 40px;
    border: 1px solid #c0c0c0;
    border-radius: 8px;
    outline: none;
    font-family: "Poppins",sans-serif;
    color: #808080;
    font-size: 13px;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    background-image: url("../../../public/assets/images/arrow.svg");
    background-repeat: no-repeat;
    background-position-x: 99%;
    background-position-y: 10px;
    ::placeholder,
    ::-webkit-input-placeholder {
      color: red;
    }
    :-ms-input-placeholder {
      color: red;
    }
}
.input {
    box-sizing: border-box;
    width: 100%;
    padding: 0px 10px;
    height: 38px;
    border: 1px solid #c0c0c0;
    border-radius: 8px;
    outline: none;
    font-family: "Poppins", sans-serif;
    color: #808080;
    transition-duration: 0.8s;
}