@tailwind utilities;

body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
select {
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem 1.75rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
p {
  margin: 0px;
  font-family: "Poppins";
}
a {
  text-decoration: none;
}
.grid-container-card-delivery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
}
.card-delivery {
  background-color: #f0f0f0;
  box-shadow: 1px 1px #888888;
  min-height: 60px;
  width: 85%;
  border-radius: 5px;
  padding: 10px;
  font-size: 13px;
  color: #3c434d;
  font-family: "Poppins";
  font-size: 11px;
  font-weight: 500;
  margin-top: 5px;
  margin-top: 10px;
  cursor: pointer;
}
.card-delivery-select {
  background-color: #b1bbcd;
  box-shadow: 1px 1px #888888;
  min-height: 60px;
  width: 85%;
  border-radius: 5px;
  padding: 10px;
  font-size: 13px;
  color: #3c434d;
  font-family: "Poppins";
  font-size: 11px;
  font-weight: 500;
  margin-top: 5px;
  margin-top: 10px;
  cursor: pointer;
}
.grid-container-card-delivery :hover {
  opacity: 0.8;
}
.label-title {
  font-weight: bold;
}
.total-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.row-cont {
  display: flex;
  align-items: center;
}
.col-cont {
  display: flex;
  flex-direction: column;
}
.flex-fill {
  flex-basis: 0;
  flex-grow: 1;
}
.flex-end-row {
  justify-content: flex-end;
}

.align-start-row {
  align-items: flex-start;
}

.flex-end-col {
  align-items: flex-end;
}
.space-between {
  justify-content: space-between;
}
.space-around {
  justify-content: space-around;
}

.flex-wrap {
  flex-wrap: wrap;
}

.error-text {
  font-size: 14px !important;
  color: #F6412D !important;
  padding-left: 13px;
  padding-top: 3px;
}
.error{
  color: #F6412D !important;
}
.disabled {
  opacity: 0.3;
}
.textErrorLabel {
  font-size: 12px;
  margin-top: 3px;
  color: #F6412D;
  font-weight: bold;
}
.img-zoom-container {
  position: relative;
  z-index: 9999999999999;
}
.img-zoom-lens {
  position: absolute;
  width: 180px;
  height: 180px;
  cursor: pointer;
}
.img-zoom-lens:hover {
  border: 1px solid #d4d4d4;
  position: absolute;
  width: 200px;
  height: 200px;
  cursor: pointer;
}
.img-zoom-result {
  border: 1px solid #d4d4d4;
  width: 420px;
  height: 329px;
  position: absolute;
  left: 420px;
  bottom: 10px;
}

.opacity-inverse {
  opacity: 0.3;
  cursor: pointer;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.opacity-inverse:hover {
  opacity: 1;
}

.opacity:hover {
  opacity: 0.4;
  cursor: pointer;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.bold {
  font-weight: 700;
}
.greenText {
  color: #49ac3d;
}
.blueText {
  color: #29b6f6;
}
.redText {
  color: #F6412D;
}

.ReactModal__Overlay {
  opacity: 0;
  /* transform: translateX(-100px); */
  transition: all 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  z-index: 1000;
  background-color: #00000080 !important;
  /* transform: translateX(0px); */
}
.item1 {
  grid-column: 1 / -1;
}
.item2 {
  grid-column: 1 / 2;
}
.item3 {
  grid-column: 2 / -1;
}
.ReactModal__Overlay--before-close {
  opacity: 0;
  /* transform: translateX(-100px); */
}

.ReactModal__Content.drawer {
  /* transform: translateX(-100px); */
  transition: all 300ms ease-in-out;
  position: absolute;
  top: 0;
  transform: translateX(-260px);
  width: 260px;
  height: 100vh;
  right: auto;
  bottom: auto;
  padding: 0;
  box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1);
  border: none;
  overflow: visible;
  outline: none;
  z-index: 99999999999999999;
}

.ReactModal__Content--after-open.drawer {
  opacity: 1;
  z-index: 1;
  background-color: #fff !important;
  transform: translateX(0px);
}

.ReactModal__Content--before-close.drawer {
  transform: translateX(-260px);
}

.ReactModal__Overlay.snackbar {
  bottom: auto;
}

.ReactModal__Content.snackbar {
  /* transform: translateX(-100px); */
  top: auto;
  left: auto;
  right: 20px;
  bottom: 20px;
  padding: 0px;
  border-radius: 4px;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.1);
  border: none;
  overflow: visible;
  position: fixed;
  background-color: #303030;
  transition: all 300ms ease-in-out;
  transform: translateY(60px);
  outline: none;
}

.ReactModal__Content.snackbar:hover {
  background-color: #505050;
}

.ReactModal__Content--after-open.snackbar {
  z-index: 1;
  transform: translateY(0px);
}

.ReactModal__Content--before-close.snackbar {
  transform: translateY(60px);
}

@media (max-width: 420px) {
  .hidden-xs {
    display: none;
  }
  .ReactModal__Content.snackbar {
    right: 0px;
    left: 0px;
    bottom: 0px;
    border-radius: 0px;
  }
}

@media (max-width: 768px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (max-width: 992px) {
  .hidden-md {
    display: none;
  }
}

@media (max-width: 1200px) {
  .hidden-lg {
    display: none;
  }
}

@media (min-width: 420px) {
  .hidden-sm-and-up {
    display: none;
  }
  /* .img-zoom-lens {
    display: none;
  }
  .img-zoom-lens:hover {
    display: none;
  } */
}

@media (min-width: 768px) {
  .hidden-md-and-up {
    display: none;
  }
}

@media (min-width: 992px) {
  .hidden-lg-and-up {
    display: none;
  }
}

div[role="button"] {
  outline: none;
}

ul.pagination {
  padding-inline-start: 0px !important;
}
.pagination ul {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}

.pagination li {
  display: inline-block;
  /* box-shadow: 0px 0px 5px #80808080; */
}
.pagination a {
  border: 1px solid #eaeaea;
  font-size: 12px;
  color: #404040;
  padding: 10px 15px;
  border-left-width: 0px;
  transition-duration: 0.3s;
  cursor: pointer;
  user-select: none;
  /* box-shadow: 0px 0px 5px #80808080; */
}

.pagination li a:hover {
  background-color: #80808080;
  /* box-shadow: 0px 0px 5px #80808080; */
}
.pagination li.active a {
  color: #fff;
  background-color: #12cad6;
  /* box-shadow: 0px 0px 5px #80808080; */
}

.pagination li.previous a {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left-width: 1px;
  margin-right: 10px;
}

.pagination li.next a {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-left: 10px;
  border-left-width: 1px;
}

li.next.disabled {
  display: none;
}
li.previous.disabled {
  display: none;
}

@media (max-width: 420px) {
  .pagination li.previous a {
    margin-right: 0px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .pagination li.next a {
    margin-left: 0px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.pagination li:nth-child(2) a {
  border-left-width: 1px;
}

/* Bar Scanner */

.scanner-container {
  width: 90%;
  margin: auto;
}
.dbrScanner-video {
  width: 100%;
}
.slick-slider.modal-product-slider button.slick-next{
  right: -48px;
}
.slick-slider.modal-product-slider button.slick-prev{
  left: -80px;
}
.slick-slider.modal-product-slider .slick-prev::before, .slick-slider.modal-product-slider .slick-next::before{
  font-size: 50px;
}
.slick-slider.dark-arrow .slick-prev::before, .slick-slider.dark-arrow .slick-next::before{
  color: black;
}
.slick-slider div:focus-visible{
  outline: none !important;
}

.Toastify__toast-theme--colored.Toastify__toast--success{
  background-color: #12cad6;
}

.Toastify__toast-theme--colored.Toastify__toast--error{
  background-color: #F6412D;
}

.Toastify__toast-theme--colored.Toastify__toast--info{
  background-color: #F4B13E;
}

.featured-text{
  color: #3c434d;
  font-family: "Poppins";
  font-weight: bold;
  font-size: 16px;
  @media (max-width: 998px) {
    font-size: 14px;
  }
}

.modal-button-close {
  position: absolute;
  right: -5px;
  top: -5px;
  background-color: #fff;
  width: 26px;
  height: 26px;
  border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.danger{
  color: #F6412D
}

.bg-danger{
  background-color: #F6412D
}

.scandit-scanner{
  height: 40vh;
  width: 100%;
  overflow: hidden;
}