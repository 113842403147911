.modal{
    position: relative;
    width: 95%;
    background-color: transparent;
    overflow: visible;
}
.modal.medium{
    max-width: 768px;
}
.modal.small{
    max-width: 425px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #7f7f7f;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    box-sizing: border-box;
    max-height: 90vh;
    padding: 16px;
    overflow: auto;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 20px 0px;
}